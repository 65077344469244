<template>
  <div class="nameAndIdentitycard-wrap">
    <div class="tip">为了保证电子签约的法律效力，您需要先进行实名认证</div>
    <div class="content">
      <div class="label">姓名</div>
      <van-field v-model="userFullName" @input="onInput" placeholder="请输入真实姓名" :disabled="disabled" />
      <div class="label">证件号</div>
      <!-- <van-field
        :value="
          userIdCardNo
            ? userIdCardNo.replace(/^(.{6})(?:\d+)(.{2})$/, '$1******$2')
            : userIdCardNo
        "
        maxlength="18"
        v-if="disabled"
        placeholder="请输入身份证号"
        :disabled="disabled"
      /> -->
      <van-field v-model="userIdCardNo" maxlength="18" placeholder="请输入证件号" />
      <!-- 1、中国国籍 2、台湾居民来往大陆通行证、台胞证 3、港澳居民来往内地通行证" -->
      <div class="label">证件类型</div>
      <van-cell is-link @click="showPopup">{{ typeStr }}</van-cell>
      <!-- <div class="des">
        <van-checkbox
          v-model="checked"
          checked-color="#1676FF"
        ></van-checkbox>
        <p>
          我已阅读并同意
          <span>《隐私保护协议》</span>
        </p>
      </div> -->
    </div>
    <div class="footer" v-if="!isPopup">
      <template v-if="canClick">
        <div class="btn" @click="twoElements">下一步</div>
      </template>
      <template v-else>
        <div class="btn btn2">下一步</div>
      </template>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker title="标题" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
        @change="onChange" />
    </van-popup>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { hasCookies } from '@/utils/cookies'
import Api from '@/api'
import myApis from '@/api/my'
import AuthApi from '@/api/creadit'
import { Dialog, Toast } from 'vant'
import { mapState } from 'vuex'

export default {
  props: ['isPopup'],
  data() {
    return {
      userFullName: '',
      userIdCardNo: '',
      checked: true,
      disabled: false,
      token: undefined,
      show: false,
      columns: ['内地', '台湾', '港澳'],
      typeStr: '内地',
      type: 1,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system,
    }),
    canClick() {
      if (this.userFullName && this.userIdCardNo && this.checked) return true
      return false
    },
  },
  created() {
    // this.$store.dispatch('resetSession')
    const { token, resetAuth } = this.$route.query
    if (token) {
      this.token = token
    }
    this.toast = Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    })
    // 重新填写信息认证
    /* eslint-disable-next-line */
    if (resetAuth == 1) {
      this.tokenInfo = this.$jsonPase(
        window.sessionStorage.getItem('tokenInfo')
      )
      if (this.tokenInfo) {
        this.setCreditType = this.tokenInfo.type
      } else {
        this.tokenInfo = {}
        this.setCreditType = 2 // 如果回来的时候没有 tokenInfo 则说明是从发起签署到实名认证页面的，要将数据从store中取出来
        this.userInfo = this.$store.getters.getUserInfo
        this.tokenInfo.telephone = this.userInfo.telephone
        this.tokenInfo.userId = this.userInfo.userId
        this.tokenInfo.creditStatus = this.userInfo.creditStatus
      }
      this.getUserInfo()
      return
    }
    if (token) {
      this.getCreditTokenInfo(token)
    } else {
      this.tokenInfo = this.$jsonPase(
        window.sessionStorage.getItem('tokenInfo')
      )
      this.userInfo = this.$store.state.user.userInfo
      this.tokenInfo.telephone = this.userInfo.telephone
      this.tokenInfo.userId = this.userInfo.userId
      this.tokenInfo.creditStatus = this.userInfo.creditStatus
      window.sessionStorage.setItem(
        'tokenInfo',
        JSON.stringify(this.tokenInfo)
      )
      this.setCreditType = this.tokenInfo.type
      this.getUserInfo()
    }
  },
  methods: {
    showPopup() {
      this.show = true
    },
    onConfirm(value, index) {
      this.typeStr = value
      this.type = index + 1
      this.show = false
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`)
    },
    onCancel() {
      this.show = false
    },
    onInput(value) {
      this.userFullName = value.replace(/[\d]/g, '')
    },
    async getUserInfo() {
      try {
        const { creditStatus, comId, userId } = this.tokenInfo
        // 认证成功
        if (creditStatus === 1) {
          // 个人实名
          if (this.setCreditType === 2) {
            await this.$router.push(
              `/authenticationSuccess?redirectUrl=${this.$route.query.redirectUrl}`
            )
            this.toast.clear()
            return
          }
          const comAuthStatus = await AuthApi.getComAuthStatus({
            comId,
            userId,
          })
          this.toast.clear()
          // 已签署授权书
          if (comAuthStatus === 7) {
            this.$router.push('/signSuccess')
            return
          }
          // 未签署授权书
          this.$router.push('/signCreaditBook')
        } else {
          // 未实名的情况
          // 公司法人认证
          this.toast.clear()
          if (this.setCreditType !== 2) {
            const { userFullName, userIdCardNo } = this.tokenInfo
            this.$store.commit('setCreditType', 1)
            this.userFullName = userFullName
            this.userIdCardNo = userIdCardNo
            this.disabled = true
          }
        }
        // 未实名
      } catch (err) {
        this.toast.clear()
        console.log(err)
      }
    },
    async getCreditTokenInfo(creditToken) {
      const res = await AuthApi.getCreditTokenInfo({ creditToken })
      // 0 分享给法人认证 1 法人认证  2 个人实名
      if (res.type === 0 && hasCookies()) {
        const data = await myApis.getUserInfo()
        res.creditStatus = data.creditStatus
        res.telephone = data.telephone
        res.email = data.email
        res.userId = data.userId
      }
      window.sessionStorage.setItem('tokenInfo', JSON.stringify(res))
      this.$store.commit('setCreditType', res.type)
      this.setCreditType = res.type
      if (res.type === 0 && !hasCookies()) {
        this.$router.replace(
          `/login?redirect=/nameAndIdentitycard?token=${this.token}`
        )
        this.toast.clear()
        return
      }
      this.tokenInfo = res
      this.userInfo = res
      this.getUserInfo()
    },
    twoElements() {
      if (this.loading) return
      let { userId } = this.tokenInfo
      if (this.tokenInfo.type === 0 && hasCookies()) {
        userId = Cookies.get('userId')
      }
      this.loading = true
      Api.twoElements({
        userFullName: this.userFullName,
        userIdCardNo: this.userIdCardNo,
        userId,
        systemId: this.systemInfo.systemId,
        type: this.type,
      })
        .then(async res => {
          const { data, status, msg } = res
          if (status === 201 && this.$store.state.setCreditType === 2) {
            Dialog.alert({
              title: '',
              message: msg,
              confirmButtonText: '重新输入',
            }).then(() => {
              this.userFullName = ''
              this.userIdCardNo = ''
            })
            return
          }
          // 要求：有没有「上限」两个字的时候展示以下错误信息
          if (
            status === 201 &&
            !msg.includes('上限') &&
            !msg.includes('已绑定')
          ) {
            Dialog.alert({
              title: '身份信息校验错误',
              message: `<p>1、检查姓名和身份证填写是否正确</p> <p>2、检查填写的身份证填写是否有效</p> <p>3、检查填写的姓名是否有效</p>`,
              allowHtml: true,
            })
            return
          }
          if (status === 200) {
            if (this.type !== 1) {
              await this.$store.dispatch('fetchUserInfo')
              const { resetAuth } = this.$route.query

              this.$router.push({
                name: '/authenticationSuccess',
                query: {
                  resetAuth,
                },
              })
            } else if (msg) {
              Dialog.alert({
                title: '',
                message: msg,
                confirmButtonText: '我知道了',
              }).then(() => {
                this.$store.commit('setCreditMsg', {
                  userFullName: this.userFullName,
                  userIdCardNo: this.userIdCardNo,
                  userId,
                  telephone: this.tokenInfo.telephone,
                })
                const { redirectUrl } = this.$route.query
                this.$router.push({
                  name: 'auth',
                  query: {
                    redirectUrl,
                  },
                })
              })
            } else {
              this.$store.commit('setCreditMsg', {
                userFullName: this.userFullName,
                userIdCardNo: this.userIdCardNo,
                userId,
                telephone: this.tokenInfo.telephone,
              })
              const { redirectUrl } = this.$route.query
              this.$router.push({
                name: 'auth',
                query: {
                  redirectUrl,
                },
              })
            }
            return
          }
          Toast(msg)
        })
        // 不知道为啥两个then,我注掉了一个
        // .then(res => {
        //   const { data, status, msg } = res
        //   if (status === 201 && this.$store.state.setCreditType === 2) {
        //     Dialog.alert({
        //       title: '',
        //       message: msg,
        //       confirmButtonText: '重新输入',
        //     }).then(() => {
        //       this.userFullName = ''
        //       this.userIdCardNo = ''
        //     })
        //     return
        //   }
        //   // 要求：有没有「上限」两个字的时候展示以下错误信息
        //   if (
        //     status === 201 &&
        //     !msg.includes('上限') &&
        //     !msg.includes('已绑定')
        //   ) {
        //     Dialog.alert({
        //       title: '身份信息校验错误',
        //       message: `<p>1、检查姓名和身份证填写是否正确</p> <p>2、检查填写的身份证填写是否有效</p> <p>3、检查填写的姓名是否有效</p>`,
        //       allowHtml: true,
        //     })
        //     return
        //   }
        //   if (status === 200) {
        //     this.$store.commit('setCreditMsg', {
        //       userFullName: this.userFullName,
        //       userIdCardNo: this.userIdCardNo,
        //       userId: this.tokenInfo.userId,
        //       telephone: this.tokenInfo.telephone,
        //     })
        //     this.$router.push('/auth')
        //     return
        //   }
        //   Toast(msg)
        // })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.nameAndIdentitycard-wrap {
  width: 100%;

  .header {
    width: 375px;
    height: 40px;
    background: rgba(248, 249, 251, 1);
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .left {
      height: 16px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(109, 119, 144, 1);
      line-height: 16px;
    }

    .right {
      display: flex;
      align-items: center;

      img {
        width: 11px;
        height: 14px;
        margin-right: 8px;
      }

      height: 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: rgba(22, 187, 131, 1);
      line-height: 16px;
    }
  }

  .tip {
    font-size: 14px;
    padding: 16px;
    color: rgba(22, 118, 255, 1);
  }

  .content {
    padding: 0 16px;

    .label {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(17, 26, 52, 1);
      line-height: 22px;
      margin-bottom: 8px;
    }

    /deep/.van-cell {
      padding: 13px 0;
      height: 48px;
      margin-bottom: 24px;
    }

    .des {
      display: flex;
      align-items: center;
      height: 24px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(109, 119, 144, 1);
      line-height: 24px;

      span {
        color: rgba(22, 118, 255, 1);
      }

      p {
        margin-left: 8px;
      }
    }

    .van-cell::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 0.02667rem solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }

    .van-cell:last-child::after {
      display: inline-block;
    }
  }

  .footer {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 375px;
    height: 66px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);
    padding-top: 10px;

    .btn {
      width: 327px;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0 6px 20px 0 rgba(78, 128, 245, 0.16);
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
      text-align: center;
      margin: 0 auto;
    }

    .btn2 {
      background: rgba(22, 118, 255, 0.2);
    }
  }
}
</style>
